<template>
    <ion-menu 
      side="start" 
      id="main-side-menu" 
      menu-id="main-side-menu" 
      :content-id="contentId" 
      type="overlay"
      >
        <ion-content>
        <div class="flex flex-col justify-between h-full">
          <div :class="['flex flex-col', nativeIosMobileDevice && 'mt-8']">
            <div v-show="userStore.userObj.loggedInUser && userStore.userObj.activeCompany" class="flex flex-row pr-3 relative">
              <grid-select
                :isCompany="true"
                :disabledModal="!userStore.userObj.companies || userStore.userObj.companies.length < 2"
                :activeCompany="userStore.userObj.activeCompany && userStore.userObj.activeCompany"
                :companies="userStore.userObj.companies && userStore.userObj.companies"></grid-select>
                <ChevronDownIcon v-show="userStore.userObj.companies && userStore.userObj.companies.length > 1" class="w-4 text-gray-600"/>
            </div>

            <ion-list
              id="inbox-list"
              class="relative overflow-hidden bg-transparent">
            <div v-for="(p, i) in currentPages"
                :key="i">
              <div

                  v-show="!p.nestedPages"
              >
                  <ion-item
                    v-show="menuItemShow(p.url) && !p.hide"
                    :disabled="disableSideBarItem(p.url)"
                    @click="selectedIndex = i; navStore.updateIndex(i); showSideMenu(p.url);"
                    router-direction="root"
                    :router-link="p.url"
                    lines="none"
                    detail="false"
                    class="hydrated cursor-pointer rounded-2xl"
                    :class="{ selected: p.url === route.path }"
                  >
                    <span class="w-5 mr-4"><DynamicHeroIcon :name="p.iosIcon" /></span>
                    <ion-label>{{ p.title }}</ion-label>
                    <div 
                      v-show="p.url === '/notifications' && nonReadNotificationsCount > 0"
                      class="flex justify-start items-start gap-2 px-3 py-0.5 rounded bg-red-500">
                      <p class="text-lg font-bold text-left text-white">{{nonReadNotificationsCount}}</p>
                    </div>
                  </ion-item>

              </div>
              <grid-ion-accordion v-show="p.nestedPages && !p.hide" :page="p"></grid-ion-accordion>
            </div>
            </ion-list>
            <div

              >
             <div v-show="userStore.userObj.loggedInUser" class="p-2">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0 mb-4">
                  <grid-button
                    v-show="showApplyForFinance"
                    @click="redirectToDashboard()"
                    variant="primary"
                    label="Apply for Finance"
                    size="full">
                  </grid-button>
                </div>
                <div v-show="!userStore.userObj.isMasqueraded" class="flex justify-start items-start flex-grow-0 flex-shrink-0">
                  <grid-button @click="handleLogout" variant="outline" label="Logout" size="full"></grid-button>
                </div>
                <div v-show="userStore.userObj.isMasqueraded" class="relative flex justify-start items-start flex-grow-0 flex-shrink-0">
                  <grid-button @click="redirectToExternalUrl(`${navStore.apiUrl}/users/masquerade/back?masqueraded_resource_class=User`)" variant="outline" label="Logout" size="full"></grid-button>
                        <p :class="pillClass">
                            Admin logged
                        </p>
                </div>
              </div>
              <div v-show="!userStore.userObj.loggedInUser" class="p-2">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0">
                  <grid-button @click="openLoginModal()" variant="outline" label="Login" size="full"></grid-button>
                </div>
              </div>
              <div v-show="!userStore.userObj.loggedInUser" class="p-2">
                <div class="flex justify-start items-start flex-grow-0 flex-shrink-0">
                  <grid-button @click="() => route.path === '/finance' ? scrollToId('finance-register-form') : redirectToPath('/finance')" variant="primary" label="Get Finance" size="full"></grid-button>
                </div>
              </div>
            </div>
            <ion-list id="labels-list" class="bg-transparent">
              <ion-item v-for="(label, index) in labels" lines="none" :key="index">
                <div class="w-full m-auto flex flex-row justify-center">{{ label }}</div>
              </ion-item>
          </ion-list>
          </div>
          <div
              class="flex justify-start items-center w-full relative gap-4 pr-[18px] pt-2 pb-0 rounded-lg"
            >

            <div id="sidenav-grid-logo z-20" @click="() => redirectToPath('/')" class="w-full flex justify-start items-center flex-grow-0 flex-shrink-0 w-28 h-12 py-8 ml-4 relative overflow-hidden gap-2">
              <grid-logo-std-text iconClass="cursor-pointer"></grid-logo-std-text>
            </div>
          </div>
        </div>

        </ion-content>
    </ion-menu>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import {
  IonMenu,
  IonContent,
  IonAccordion,
  IonAccordionGroup,
  IonMenuController,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  //IonMenuToggle,
  IonNote,
  modalController,
  menuController,
  isPlatform
} from "@ionic/vue";
import { defineComponent, defineAsyncComponent, ref, inject, onMounted, onUpdated, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import GridLogoStdText from "@/components/icons/GridLogoStdText";
import GridIonAccordion from "@/components/ui/GridIonAccordion";
import GridSelect from "@/components/ui/GridSelect";
import {
        TemplateIcon,
        DocumentTextIcon,
        DatabaseIcon,
        CreditCardIcon,
        BookmarkIcon,
        ChatAlt2Icon,
        UserCircleIcon,
        OfficeBuildingIcon,
        LogoutIcon,
        ChevronDownIcon
} from '@heroicons/vue/outline'
import ProfileIcon from '@/components/icons/ProfileIcon';
import GridLoader from '@/components/icons/GridLoader';
import useDashboardDataApi from "@/apis/useDashboardDataApi.js";
import { useUserStore } from "@/stores/user";
import { useNavStore } from "@/stores/nav";
import GridButton from "@/components/ui/GridButton";
import LoginModal from "@/components/ui/modals/LoginModal";
import NotificationModal from "@/components/ui/modals/NotificationModal";
import useAuthApi from "@/apis/useAuthApi.js";
import { useNotificationStore } from "@/stores/notification";
import useNotificationsApi from "@/apis/useNotificationsApi.js";
import DynamicHeroIcon from "@/components/icons/DynamicHeroIcon";
import useOnboarding from "@/apis/useOnboardingApi.js";

export default defineComponent({
  name: "SideBarNav",
  components: {
    IonMenu,
    IonContent,
    IonItem,
    IonLabel,
    IonList,
    //IonMenuToggle,
    GridIonAccordion,
    GridLogoStdText,
    GridButton,
    GridSelect,
    ChevronDownIcon,
    DynamicHeroIcon,
  },
  props: {
    pages: {
        type: Array,
        default: () => []
    },
    labels: {
        type: Array
    },
    contentId: {
        type: String
    },
    menuId: {
        type: String
    },
    resetSideBar: {
        type: Function
    },
    pillClass: {
        type: String,
        default: () => "cursor-default border border-blue-500 rounded-sm bg-blue-50 px-[.15rem] py-[.15rem] absolute top-3 right-2 text-[8px] md:text-[10px] font-semibold text-left text-[#206bfa]"
    }
  },
  setup(props) {
    const { enableFinanceOrAnalytics } = useOnboarding();
    const isNativeApp = ref(Capacitor.isNativePlatform());
    const nativeIosMobileDevice = isPlatform('ipad') || isPlatform('iphone');
    //const hybridMobile = isPlatform('android') || isPlatform('ipad') || isPlatform('iphone');
    const isMobile = ref(isPlatform('mobile'));
    const notificationStore = useNotificationStore();
    const { getLatestNotifications, getAllNotifications } = useNotificationsApi();
    const loading = ref(true);
    const loadingFinished = ref(false);
    const emitter = inject('emitter');
    const selectedIndex = ref(0);
    const pagesWithSideMenu = ref(['/dashboard']);
    const router = useRouter()
    const route = useRoute();
    const path = window.location.pathname;
    const { logoutUser } = useAuthApi();
    const userStore = useUserStore();
    const navStore = useNavStore();
    const { getLastUpdatedDate } = useDashboardDataApi();
    const existingCompany = ref({});
    const showApplyForFinance = ref(false);
    const nonReadNotificationsCount = ref(0);
    const currentPages = ref([]);
    const count = ref(15);

    if (path !== undefined) {
      selectedIndex.value = props.pages.findIndex(
        (page) => page.url === path.toLowerCase()
      );
      navStore.updateIndex(selectedIndex.value);
    }

    if (userStore.userCookie) userStore.userObj.loggedInUser = true;

    const nestedPages = props.pages.map(page => {
      if (page.nestedPages) {
        return page.nestedPages.map(nestedPage => nestedPage.meta.showSideMenu && nestedPage.url).filter(Boolean)
      }
    }).filter(Boolean).flat();

    pagesWithSideMenu.value = (props.pages.map(page => page.meta.showSideMenu && page.url)).filter(Boolean).concat(nestedPages);

    /* Custom solution to handle back button on ionic vue */
    window.onpopstate = function(event) {
      if (event.state.current !== undefined) {
        router.go();
        selectedIndex.value = props.pages.findIndex(
          (page) => page.url === event.state.current.toLowerCase()
        );
      }
    };

    const redirectToPath = (path) => {
      selectedIndex.value = props.pages.findIndex(
        (page) => page.url === path.toLowerCase()
      );
      navStore.updateIndex(selectedIndex).then(() => {
        emitter.emit('disableVidPlay', true);
        //router.push(path);
        window.location.href=path
      });
    }

    const redirectToExternalUrl = async (externalUrl) => {
        if (externalUrl) {
            await userStore.removeActiveCompanyAccount();
            window.location.href = externalUrl
        }
    }

    const updateFinance = async () => {
      let productType = '';
      if(userStore.userObj.hasFinance && !userStore.userObj.hasAnalytics) {
        productType = 'finance';
      }
      let result = productType.length > 0 && await enableFinanceOrAnalytics(navStore.apiUrl, navStore.authToken, userStore.userObj.token, userStore.userObj.companies && userStore.userObj.activeCompany ? userStore.userObj.activeCompany : userStore.userObj.companies ? userStore.userObj.companies[0] : {}, productType);
      if(result.success) {
        return true;
      } else {
        return false;
      }
    }

    const redirectToDashboard = async () => {
      await updateFinance();
      //router.push({ path: '/onboarding/loan_application', query: { path: 'loan_amount' } });
      window.location.href="/onboarding/loan_application?path=loan_amount"
    }

    const menuItemShow = async (path) => {
      if (isNativeApp.value && path === '/') {
        return false
      } else {
        return true
      }
    }

    const disablePath = (path) => {
        return !navStore.publicPages.includes(path);
    }

    const disableSideBarItem = (path) => {
      if(navStore.publicPages.includes(path)) {
        return false
      }
      if (isNativeApp.value || path === '/account/billing' || path === '/notifications' || path === '/downloads' || path === '/home/profile') {
       /* if (path !== '/analtics/billing/ios' || path !== '/notifications') {
          return true
        }*/
      } else {
        return !navStore.enabledPages.includes(path);
      }
    }

    const openLoginModal = async () => {
      const modal = await modalController.create({
        component: LoginModal,
        id: 'LoginModal',
        componentProps: {
          title: ''
        },
      });
      return modal.present();
    };

    const handleLogout = async () => {
      const response = await logoutUser(navStore.apiUrl, navStore.authToken, userStore.userObj.token);
      if (response.success) {
        await userStore.removeUserFromLocalStorage('GridUser');
        if (isNativeApp.value) {
          //router.push('/login');
          window.location.href="/login"
        } else {
          //router.push('/');
          window.location.href="/"
          showSideMenu('/');
        }
      } else {
        await userStore.removeUserFromLocalStorage('GridUser');
        //router.push('/login');
        window.location.href="/login"
      }
    };

    const showSideMenu = async (path) => {
      if(route.matched && route.matched.length > 0 && route.matched[0].path === '/:pathMatch(.*)*') {
        menuController.enable(false, 'main-side-menu');
        menuController.close('main-side-menu');
      }
      else if (!navStore.publicPages.includes(path) && path !== '/account/billing' && !path.includes('/blog')) {
        menuController.enable(true, 'main-side-menu');
        menuController.open('main-side-menu');
      } else {
        menuController.enable(false, 'main-side-menu');
        menuController.close('main-side-menu');
      }
    };

      /*emitter.on('toggleSideMenuEvent', (bool) => {
        if (navStore.showSideNav || bool ) {
          menuController.enable(true, 'main-side-menu');
          menuController.open('main-side-menu');
        } else {
          menuController.enable(false, 'main-side-menu');
          menuController.close('main-side-menu');
        }
      });*/

      /*emitter.on('toggleSideMenuEventFromArrow', (bool) => {
        if (bool) {
          menuController.enable(true, 'main-side-menu');
          menuController.open('main-side-menu');
            } else {
          menuController.enable(false, 'main-side-menu');
          menuController.close('main-side-menu');
            }

      });*/

    const financeCreateApplicationAvailable = async () => {
      if(existingCompany.value && existingCompany.value.products_config) {
        showApplyForFinance.value = existingCompany.value.products_config.application_eligible;
      } else {
        showApplyForFinance.value = true;
      } 
    }

    const hideAnalyticsHomeCalendar = () => {
      if(count.value > 0) {
        let myCount = 0;
        var refreshIntervalId = setInterval(() => {
            myCount += 1;
            if(count.value > 0){
              count.value -= 1;
              if(count.value === 1) {
                if(!route.matched[0].props.default.isPublic) { 
                  if(currentPages.value[0].nestedPages[0]) currentPages.value[0].nestedPages[0].hide = false;
                  if(currentPages.value[0].nestedPages[1]) currentPages.value[0].nestedPages[1].hide = false;
                }
              }
            } 
        }, 1000);
        if (myCount > 15) {
          clearInterval(refreshIntervalId);
        }
      } 
    }
    const updateSheets = async (pages) => {
      if(pages) {
        let myPages = pages;
        console.log('myPages: ', myPages)
        if(myPages.length > 0) {
          if(route.path === "/home/welcome") hideAnalyticsHomeCalendar();
          myPages[0].hide = !userStore.userObj.analyticsBeta;
          console.log('route: ', route);
          if(myPages[0].nestedPages[0]) myPages[0].nestedPages[0].hide = route.path === "/home/welcome";
          if(myPages[0].nestedPages[1]) myPages[0].nestedPages[1].hide = route.path === "/home/welcome";
          if(myPages[0].nestedPages[2]) myPages[0].nestedPages[2].hide = (userStore.userObj && userStore.userObj.subscription?.plan_name !== 'Premium');
          if(myPages[0].nestedPages[3]) myPages[0].nestedPages[3].hide = (userStore.userObj && userStore.userObj.subscription?.plan_name !== 'Premium');
          if(myPages[0].nestedPages[4]) myPages[0].nestedPages[4].hide = (userStore.userObj && userStore.userObj.subscription?.plan_name !== 'Premium');
          if(myPages[0].nestedPages[5]) myPages[0].nestedPages[5].hide = (userStore.userObj && userStore.userObj.subscription?.plan_name !== 'Premium');
          if(myPages[0].nestedPages[6]) myPages[0].nestedPages[6].hide = (userStore.userObj && userStore.userObj.subscription?.plan_name !== 'Premium');
          if(myPages[0].nestedPages[7]) myPages[0].nestedPages[7].hide = (userStore.userObj && userStore.userObj.subscription?.plan_name !== 'Premium');       
        }
        if(myPages.length > 1) {
          myPages[1].hide = (userStore.userObj && !userStore.userObj.hasFinance);
        }
        return myPages
      }

    }

    const getUrlParams = async () => {
        let params = new URLSearchParams(window.location.search);
        if (params.get('path') !== null) {
            return params.get('path')
        }
    }

    onMounted(async () => {
      let openExplore = await getUrlParams();
      if(openExplore === 'explore') {
        menuController.enable(true, 'main-side-menu');
        menuController.open('main-side-menu');
      }
      if(route.matched[0].props.default.isPublic) {
        await menuController.enable(false, 'main-side-menu');
        currentPages.value = route.matched[0].props.default.pages; 
      }

      else currentPages.value = await updateSheets(route.matched[0].props.default.pages);
      if(!currentPages.value) currentPages.value = route.matched[0].props.default.pages; 

            console.log('route: ', route);
      
      //if (!isNativeApp.value) await showSideMenu(route.path);
      if (((userStore.userCookie && userStore.userCookie === 'true') || userStore.userObj.loggedInUser ) && !navStore.publicPages.includes(route.path)) {
        let sheets = await getLastUpdatedDate(navStore.apiUrl, navStore.authToken, userStore.userObj.token, userStore.userObj.accounts && userStore.userObj.activeAccount ? userStore.userObj.activeAccount.id : userStore.userObj.accounts ? userStore.userObj.accounts[0].id : '');
        if(sheets) navStore.getLastUpdated(sheets);

        let data = await getLatestNotifications(navStore.apiUrl, navStore.authToken, userStore.userObj.token, 10, 1);
        if(data && data.total_not_read) notificationStore.updateUnreadNotifications(data.total_not_read);
      }
      if (userStore && userStore.userObj) {
        existingCompany.value = userStore.userObj.companies && userStore.userObj.activeCompany ? userStore.userObj.activeCompany : userStore.userObj.companies ? userStore.userObj.companies[0] : null;
      }
      await financeCreateApplicationAvailable();
      if(userStore.userObj && userStore.userObj.id) {
        let notifications = await getAllNotifications(navStore.apiUrl, navStore.authToken, userStore.userObj.token);
        nonReadNotificationsCount.value = notifications && notifications.data.total_not_read;
      }
    })


    return {
      isNativeApp,
      menuItemShow,
      notificationStore,
      selectedIndex,
      TemplateIcon,
      DocumentTextIcon,
      DatabaseIcon,
      CreditCardIcon,
      BookmarkIcon,
      ChatAlt2Icon,
      UserCircleIcon,
      OfficeBuildingIcon,
      LogoutIcon,
      navStore,
      userStore,
      route,
      router,
      pagesWithSideMenu,
      showApplyForFinance,
      loading,
      nativeIosMobileDevice,
      nonReadNotificationsCount,
      currentPages,
      redirectToExternalUrl,
      redirectToDashboard,
      handleLogout,
      openLoginModal,
      redirectToPath,
      disableSideBarItem,
      showSideMenu,
      isSelected: (url) => (url === route.path ? "selected" : ""),
    };
  },
});
</script>

<style>

.ios #sidenav-grid-logo {
  padding-top: 2rem !important;
}

.split-pane-visible > ion-menu {
  max-width: 304px !important; /* 15% !important; */
}

ion-menu ion-content {
  --background: #F9FAFB;
}

ion-item {
  --background: transparent;
  --color: #6D738C;
}

@media only screen and (max-width: 768px) {
  .item .sc-ion-label-ios-h {
      text-overflow: clip; 
  }
}

div.item-native {
  background: transparent;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-accordion-group ion-accordion ion-list {
  padding: 0 !important;;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  /*border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);*/
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 8px;
}

ion-menu.md ion-item.selected {
  --background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.02) 0px 0px 0px 1px;
}

ion-menu.md ion-item:hover {
  --background: #eaf2fa;
  /*border: 1px solid #206BFA;*/
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}
ion-item.selected {
  --color: #206BFA;
}
ion-accordion-group ion-accordion ion-item {
  --background: #F9FAFB;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: #206BFA;
}

ion-accordion.accordion-expanding ion-item[slot="header"] .ion-accordion-toggle-icon,
ion-accordion.accordion-expanded ion-item[slot="header"] .ion-accordion-toggle-icon {
  color: #206BFA;
}

.ion-accordion-toggle-icon{
  font-size: 16px;
}

.ion-accordion-toggle-icon:hover {
  color: #206BFA;
}

</style>
