// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27%3E%3Ccircle cx=%27100%27 cy=%2750%27 r=%2740%27 stroke=%27black%27 stroke-width=%272%27 fill=%27red%27/%3E%3Cpolyline points=%2720,20 40,25 60,40 80,120 120,140 200,180%27 style=%27fill:none;stroke:black;stroke-width:3%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.action-sheet-group {\n        width: 100%;\n        position: absolute;\n        top: 30%;\n}\n.action-sheet-group-cancel {\n        display: none; \n        position: absolute;\n        top: 25%;\n}\n.action-sheet-group-cancel.sc-ion-action-sheet-md {\n        padding-top: .5rem !important;\n}\nion-select::part(icon) {\n        display: none !important;\n}\n.action-sheet-group.sc-ion-action-sheet-md {\n        border-radius: 1rem;\n        padding-top: 1rem;\n        padding-bottom: 1rem;\n}\n.action-sheet-button.sc-ion-action-sheet-md {\n        padding: 2rem;\n}\n.action-sheet-group.sc-ion-action-sheet-md:first-child {\n      padding-top: 1rem;\n}\n.action-sheet-button-inner::before {\n      content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
