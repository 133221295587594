import { Capacitor } from '@capacitor/core';
import { defineStore } from 'pinia'
import { inject, ref } from "vue"
import { Preferences } from '@capacitor/preferences'
import { useStorage } from "@vueuse/core"
import VueCookies from 'vue-cookies'

export const useUserStore = defineStore("user", {

    state: () => ({
        $cookies: inject('$cookies'),
        userCookie: VueCookies.get('logged_user'),
        userObj: useStorage("GridUser", {
            name: '',
            email: '',
            token: '',
            phone: null,
            fbToken: '',
            firebase_tokens: [],
            companies: null,
            activeCompany: null,
            plaidReadyFlag: false,
            isAdmin: false,
            isBorrower: false,
            isSignatory: false,
            isMasqueraded: false,
            subscription: null,
            hasCcr: false,
            cashflow2WeeksEnabled: null,
            showTooltip: false,
            hasCompanyHealth: false,
            displayWelcomeScreen: false,
            hasAnalytics: false,
            hasFinance: false,
            hasCashflow: false,
            hasOpenBanking: false,
            journeyOnboarding: false,
            firstJourneyCompleted: null,
            subscription: null,
            gridScoreReadyFlag: false,
            analyticsBeta: true,
            merchantReadyFlag: false,
            profileCompletedFlag: false,
            onBoardingComplete: false,
            loanApplicationId: null,
            accounts: null,
            activeAccount: null,
            onboardingPath: null,
            loggedInUser: false

        })
    }),

    actions: {
        async getUserFromCookie(name) {
            const loggedUserCookie = this.$cookies.get(name);
            if (loggedUserCookie) {
                this.userObj.loggedInUser = true;
                return true;
            } else {
                return false;
            }
        },
        async evalWhitelistAnalyticsUsers(userEmail) {
            console.log('userEmail: ', userEmail);
            let emailArr = [
                'arno@flyingwild.ie'
                , 'gala.killenard@outlook.com'
                , 'degan@eganhospitality.ie'
                , 'george@feelgoodbicycles.ie'
                , 'kate@stoves4u.ie'
                , 'naoisefitzgerald96@hotmail.com'
                , 'rcourtney@bill-moss.com'
                , 'ross@eatallreal.com'
                , 'rowan@kraftagency.ie'
                , 'seantaaffe99@gmail.com'
                , 'info@back-shop.com'
                , 'shanemitchell@asador.ie'
                , 'todorstanev@me.com'
                , 'xldundrum@gmail.com'
                , 'bkelly@businesspost.ie'
                , 'gavin.keogh@winesdirect.ie'
                , 'derekfoleybutler@proton.me'
                , 'brownesonthegreen@gmail.com'
                , 'cliff@brubrewery.ie'
                , 'nicholas.s.dimaio@gmail.com'
                , 'konman1984@hotmail.com'
                , 'paul.heeney@onlineteetimes.ie'
                , 'mjcrowe09@gmail.com'
                , 'kevintowey@toweygroup.com'
                , 'anthony@norecabs.ie'
                , 'ron@hairstylist.ie'
                , 'stephen@firehousepizza.ie'
                , 'jessica@primesteakltd.com'
                , 'oxanazgherea@me.com'
                , 'star.adel@gmail.com'
                , 'freddie@copygraphics.ie'
                , 'liam@itworxtechrepairs.com'
                , 'info@oldebridge.ie'
                , 'cliff@brubrewery.ie'
                , 'thebunkerclonmel@gmail.com'
                , 'jlinnane@clarecoasthotels.com'
                , 'michael@nolaclan.com'
                , 'sangriagalway@gmail.com'
                , 'fourseasonteam@gmail.com'
                , 'alison@tipperarycrystal.com'
                , 'curleyheadoffice@gmail.com'
                , 'majorstrading@mail.com'
                , 'ronan@carterbeautycosmetics.com'
                , 'qasimali500@gmail.com'
                , 'keith.athloneprinting@gmail.com'
                , 'sattifz16@gmail.com'
                , 'meabhdhkilroy@gmail.com'
                , 'niallfitzharris@gmail.com'
                , 'greg@littleass.ie'
                , 'terry@ecohouse.ie'
                , 'brianleegw@gmail.com'
                , 'lesley@hungryduck.ie'
                , 'james@libertyirishtours.ie'
                , 'ciarafox15@gmail.com'
                , 'azeezoa3@gmail.com'
                , 'daragh1@purehealth.ie'
                , 'accounts@coastrosslarestrand.com'
                , 'darren@allaboutsunday.com'
                , 'akefabushabab@yahoo.com'
                , 'rkearns@salesforce.com'
                , 'iankeegan@gmail.com'
                , 'newbridge@itstar.ie'
                , 'klawlor619@gmail.com'
                , 'cspann1221@hotmail.co.uk'
                , 'coletteclearbrownesonthegreen@gmail.com'
                , 'cfranceskelly@gmail.com'
                , 'alan@alanclancy.com'
                , 'annelenehanhudson@yahoo.co.uk'
                , 'info@poppyseed.ie'
                , 'info@labodega.ie'
                , 'degan@eganhospitality.ie'
                , 'anne@thecardcompany.ie'
                , 'pmmurray2006@yahoo.ie'
                , 'james@olympiainvestments.ie'
                , 'tomas.butchersbest@gmail.com'
                , 'gillskiphire1@gmail.com'
                , 'padraig@quillskillarney.com'
                , 'ciarafox15@gmail.com'
                , 'deirdre170308@gmail.com'
                , 'dasiap130@gmail.com'
                , 'christerry252@icloud.com'
                , 'info@saddlesandstyle.ie'
                , 'dairine.nolan@yahoo.com'
                , 'connollytce@hotmail.com'
                , 'kelly@kildarehousehotel.ie'
                , 'info@squaredental.ie'
                , 'briandunne100@yahoo.ie'
                , 'niall@fusionfood.ie'
                , 'blessensebastian@gmail.com'
                , 'johnkinsella22@eircom.net'
                , 'lynda@michaelgrantmotors.com'
                , 'karlobrien@live.com'
                , 'rahmanhossen@yahoo.com'
                , 'mark@appliedpi.com'
                , 'dononofrei@gmail.com'
                , 'jlawlor07@yahoo.ie'
                , 'indiantiffinsdublin1@gmail.com'
                , 'johngriffinbutchers@gmail.com'
                , 'alison@agri-promotions.com'
                , 'mannionnews@eircom.net'
                , 'hiw@eircom.net'
                , 'tg.grace90@gmail.com'
                , 'arun_antony@yahoo.com'
                , 'colinhanly35@gmail.com'
                , 'habibullah1.barakzai@gmail.com'
                , 'accounts@germanautoparts.ie'
                , 'academybarber1@gmail.com'
                , 'akefabushabab@yahoo.com'
                , 'adnanshabab@yahoo.com'
                , 'osheanews@outlook.com'
                , 'atifakbar2003@hotmail.com'
                , 'clubhouse_restaurant@yahoo.co.uk'
                , 'don@alphacoltd.com'
                , 'kdrew@thejunctionvicarsroad.ie'
                , 'martin@pcpstructures.ie'
                , 'helenfagan82@gmail.com'
                , 'nshel001@gmail.com'
                , 'aisling@rockfielddairy.com'
                , 'michaelflanagan@gridfinance.ie'
                , 'michael@rockfielddairy.com'
                , 'info@empress.ie'
                , 'kcullen1980@gmail.com'
                , 'brendan@southwilliamspa.com'
                , 'joseph@southwilliamspa.com'
                , 'admin@rubys.ie'
                , 'klawlor619@gmail.com'
                , 'robk103@hotmail.com'
                , 'star.adel@gmail.com'
                , 'ciaramlynchburke@gmail.com'
                , 'info@sageandstone.com'
                , 'lizzielynch2705@gmail.com'
                , 'lizzielynch45@gmail.com'
                , 'kafeuathlone@gmail.com'
                , 'gerryrafter@gmail.com'
                , 'george@feelgoodbicycles.ie'
                , 'hello@greeneroom.ie'
                , 'orla@greeneroom.ie'
                , 'shaun@greeneroom.ie'
                , 'felipe@ondway.delivery'
                , 'info@itspot.ie'
                , 'lovagecompany@gmail.com'
                , 'magdalenamichalewicz@gmail.com'
                , 'carafinnfarmfoods@gmail.com'
                , 'jennnydam@hotmail.com'
                , 'ruby138ie@gmail.com'
                , 'patrick@ifoaminsulation.ie'
                , 'lm@customerly.io'
            ];
            let hasAccess = emailArr.includes(userEmail);
            console.log('hasAccess: ', hasAccess);
            return new Promise((resolve, reject) => {
                if (emailArr.includes(userEmail)) resolve(true)
                else resolve(false)
            });
        },
        async evalUserSideNavAuth() {
            return this.userObj.loggedInUser;
        },
        async createUserCookie(name, value) {
            if (!Capacitor.isNativePlatform()) this.$cookies.set(name, value);
        },
        async instantiateUserCookie(value) {
            this.$cookies = value;
        },
        async removeUserObj() {
            this.userObj = {
                name: '',
                email: '',
                phone: null,
                token: '',
                fbToken: '',
                firebase_tokens: [],
                companies: null,
                activeCompany: null,
                plaidReadyFlag: false,
                isAdmin: false,
                isBorrower: false,
                isSignatory: false,
                isMasqueraded: false,
                subscription: null,
                hasCcr: false,
                cashflow2WeeksEnabled: null,
                showTooltip: false,
                hasCompanyHealth: false,
                displayWelcomeScreen: false,
                hasAnalytics: false,
                hasFinance: false,
                hasCashflow: false,
                journeyOnboarding: false,
                firstJourneyCompleted: null,
                subscription: null,
                gridScoreReadyFlag: false,
                analyticsBeta: true,
                merchantReadyFlag: false,
                profileCompletedFlag: false,
                onBoardingComplete: false,
                loanApplicationId: null,
                accounts: null,
                activeAccount: null,
                onboardingPath: null,
                loggedInUser: false
            };
            //this.$cookies.remove(name);
        },
        async removeActiveCompanyAccount() {
            this.userObj.activeCompany = {};
            this.userObj.activeAccount = {};
        },
        async updateActiveCompany(company) {
            this.userObj.activeCompany = company;
            this.userObj.plaidReadyFlag = company.products_config && company.products_config.plaid_transactions_ready;
            this.userObj.gridScoreReadyFlag = company.products_config && company.products_config.grid_score_ready;
            this.userObj.merchantReadyFlag = company.products_config && company.products_config.payable_cash_advance_loans_present && company.products_config.payable_cash_advance_loans_present;
        },
        async updateHasFinanceAnalytics(company) {
            this.userObj.hasAnalytics = company.products_config && company.products_config.analytics_enabled;
            this.userObj.hasFinance = company.products_config && company.products_config.finance_enabled;
        },
        async enableFinanceAnalyticsLocally(type) {
            return new Promise((resolve, reject) => {
                if (type === 'finance') { resolve(this.userObj.hasFinance = true) }
                if (type === 'analytics') { resolve(this.userObj.hasAnalytics = true) }
            });
        },
        async checkIfUserLoggedIn() {
            return this.userObj.loggedInUser;
        },
        async updateActiveAccount(account) {
            this.userObj.activeAccount = account;
        },
        async updateFbToken(token, source) {
            //this.userObj.fbToken = token;
            if (!this.userObj.firebase_tokens) this.userObj.firebase_tokens = [];

            if (this.userObj.firebase_tokens.length > 0) {
                const newToken = this.userObj.firebase_tokens.find(element => {
                    if (element.source === source && element.token !== token) {
                        return true;
                    }
                    return false;
                });
                const newSource = this.userObj.firebase_tokens.find(element => {
                    if (element.source !== source && element.token !== token) {
                        return true;
                    }
                    return false;
                });

                if (newSource) {
                    this.userObj.firebase_tokens.push({
                        token: token,
                        source: source
                    });
                    return true;
                } else if (newToken) {
                    this.userObj.firebase_tokens.push({
                        token: token,
                        source: source
                    });
                    return true;
                } else {
                    return false;
                }
            } else {
                this.userObj.firebase_tokens.push({
                    token: token,
                    source: source
                });
                return true
            }
        },
        async getUserMobilePreferences(name) {
            let loggedUserPreferences = await Preferences.get({ key: name });
            if (loggedUserPreferences) {
                return JSON.parse(loggedUserPreferences.value);
            }
        },
        async setUserMobilePreferences(name, userObjMobile) {
            await Preferences.set({
                key: name,
                value: JSON.stringify({ userObjMobile })
            });
        },
        async removeUserFromLocalStorage(name) {
            await this.removeUserObj();
            localStorage.removeItem(name);
        },
        async waitForUserLogin() {
            return new Promise((resolve, reject) => {
                resolve(this.userObj.loggedInUser = true);
            });
        },
        async identifyUserOnHeap(userId) {
            if (userId && this.userObj.email) {
                if (window?.heap) window.heap.identify(this.userObj.email);
            }
        },
        async updateSheets(hideBool) {
            console.log('this.email: ', this.userObj.email);
            let analyticsBeta = this.userObj.analyticsBeta;
            return [
                /*{
                  title: "Home",
                  name: "Home",
                  url: "/",
                  iosIcon: 'HomeIcon',
                  meta: { showSideMenu: false },
                  mdIcon: 'HomeIcon',
                },*/
                {
                    title: "Analytics",
                    name: "Analytics",
                    meta: { showSideMenu: true },
                    iosIcon: 'ChartPieIcon',
                    mdIcon: 'ChartPieIcon',
                    hide: !analyticsBeta, //hideBool
                    nestedPages: [
                        {
                            title: "Home",
                            name: "Home",
                            url: "/home",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Calendar",
                            name: "Calendar",
                            url: "/home/calendar",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        /*{
                          title: "GRID Score",
                          name: "GRID Score",
                          url: "/analytics/grid_score",
                          iosIcon: '',
                          meta: { showSideMenu: true },
                          mdIcon: '',
                        },*/
                        /*{
                            title: "Credit Bureau Report",
                            name: "Credit Bureau Report",
                            url: "/analytics/credit_bureau_report",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                            hide: !this.userObj.hasCcr
                        },*/
                        {
                            title: "Banking Analysis",
                            name: "Your Bank Account",
                            url: "/analytics/banking_analysis",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                            hide: (this.userObj && !this.userObj.hasCashflow),
                        },
                        /*{
                            title: "Company Health",
                            name: "Company Health",
                            url: "/analytics/company_health",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                            hide: !this.userObj.hasCompanyHealth
                        },*/
                        {
                            title: "Transaction Analysis",
                            name: "Account Transactions",
                            url: "/analytics/transaction_analysis",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                            hide: (this.userObj && !this.userObj.hasCashflow),
                        },
                        {
                            title: "Categorised Transaction Analysis",
                            name: "Account Trends",
                            url: "/home/debt_history",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                            hide: (this.userObj && !this.userObj.hasCashflow),
                        },
                        {
                            title: "Debt History",
                            name: "Debt History",
                            url: "/analytics/categorised_transaction_analysis",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                            hide: (this.userObj && !this.userObj.hasCashflow),
                        },
                        /*{
                          title: "Sector Comparison",
                          name: "Similar Businesses",
                          url: "/analytics/sector_comparison",
                          iosIcon: '',
                          meta: { showSideMenu: true },
                          mdIcon: '',
                        },
                        {
                          title: "Macroeconomic Hub",
                          name: "Economic Trends",
                          url: "/analytics/macroeconomic_hub",
                          iosIcon: '',
                          meta: { showSideMenu: true },
                          mdIcon: '',
                        },*/
                    ]
                },
                /*{
                  title: "Pricing",
                  name: "Pricing",
                  url: nativeIosMobileDevice ? "/account/billing/ios" : "/account/billing",
                  iosIcon: CashIcon,
                  meta: { showSideMenu: true },
                  mdIcon: CashIcon,
                },*/
                {
                    title: "Finance",
                    name: "Finance",
                    meta: { showSideMenu: true },
                    iosIcon: 'CreditCardIcon',
                    mdIcon: 'CreditCardIcon',
                    hide: (this.userObj && !this.userObj.hasFinance),
                    nestedPages: [
                        {
                            title: "Finance Dashboard",
                            name: "Finance Dashboard",
                            url: "/finance/dashboard",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Loan Applications",
                            name: "Loan Applications",
                            url: "/finance/application",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Your Loans",
                            name: "Your Loans",
                            url: "/finance/loans",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Loan Repayments",
                            name: "Loan Repayments",
                            url: "/finance/repayments",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Your Leases",
                            name: "Your Leases",
                            url: "/finance/leases",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Lease Repayments",
                            name: "Lease Repayments",
                            url: "/finance/lease-repayments",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Term Loan Repayments",
                            name: "Term Loan Repayments",
                            url: "/finance/term_repayments",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Merchant Transactions",
                            name: "Merchant Transactions",
                            url: "/finance/merchant_transactions",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        /*{
                          title: "Merchant Turnover",
                          name: "Your Card Income",
                          url: "/finance/turnover",
                          iosIcon: '',
                          meta: { showSideMenu: true },
                          mdIcon: '',
                        },*/
                        {
                            title: "Terminal Income",
                            name: "Terminal Income",
                            url: "/finance/terminal_income",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Merchant Changeover",
                            name: "Merchant Changeover",
                            url: "/finance/merchant_changeover",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                    ]
                },
                {
                    title: "Notifications",
                    name: "Notifications",
                    url: "/notifications",
                    iosIcon: 'BellIcon',
                    meta: { showSideMenu: true },
                    mdIcon: 'BellIcon',
                    hide: hideBool,
                },
                {
                    title: "Downloads",
                    name: "Downloads",
                    url: "/downloads",
                    iosIcon: 'DownloadIcon',
                    meta: { showSideMenu: true },
                    mdIcon: 'DownloadIcon',
                    hide: hideBool,
                },
                {
                    title: "Help Centre",
                    name: "Help Centre",
                    meta: { showSideMenu: false },
                    iosIcon: 'InformationCircleIcon',
                    mdIcon: 'InformationCircleIcon',
                    hide: hideBool,
                    nestedPages: [
                        /*{
                          title: "Chat to someone",
                          name: "Chat to someone",
                          url: "/analytics/dashboard",
                          iosIcon: '',
                          meta: { showSideMenu: true },
                          mdIcon: '',
                        },
                        {
                          title: "Contact us",
                          name: "Contact us",
                          url: "/analytics/demo_dashboard_2",
                          iosIcon: '',
                          meta: { showSideMenu: true },
                          mdIcon: '',
                        },
                        {
                          title: "FAQ's",
                          name: "FAQ's",
                          url: "/analytics/dashboard",
                          iosIcon: '',
                          meta: { showSideMenu: true },
                          mdIcon: '',
                        },*/
                        {
                            title: "Your Dashboard",
                            name: "Your Dashboard",
                            url: "/help_centre/guide",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Support",
                            name: "Support",
                            url: "/help_centre/support",
                            hide: false,
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Privacy Policy",
                            name: "Privacy Policy",
                            url: "/help_centre/privacy_policy",
                            hide: false,
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Complaints Policy",
                            name: "Complaints Policy",
                            url: "/help_centre/complaints_policy",
                            hide: false,
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Terms & Conditions",
                            name: "Terms & Conditions",
                            url: "/help_centre/terms_conditions",
                            hide: false,
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Resources",
                            name: "Resources",
                            url: "https://app.gridfinance.ie/shared/support/resources",
                            externalUrl: true,
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "FAQ's",
                            name: "FAQ's",
                            url: "/help_centre/faqs",
                            iosIcon: '',
                            meta: { showSideMenu: false },
                            mdIcon: '',
                        },
                    ]
                },
                {
                    title: "Settings",
                    name: "Settings",
                    meta: { showSideMenu: true },
                    iosIcon: 'CogIcon',
                    mdIcon: 'CogIcon',
                    hide: hideBool,
                    nestedPages: [
                        {
                            title: "My Company",
                            name: "My Company",
                            url: "/account/company_details",
                            iosIcon: '',
                            hide: true,
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "User Profile",
                            name: "User Profile",
                            url: "/account/user_profile",
                            iosIcon: '',
                            hide: true,
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                        {
                            title: "Identification",
                            name: "Identification",
                            url: "/account/identification",
                            iosIcon: '',
                            meta: { showSideMenu: false },
                            mdIcon: '',
                        },
                        {
                            title: "Billing",
                            name: "Billing",
                            hide: true,
                            url: "/account/billing",//nativeIosMobileDevice ? "/account/billing/ios" : "/account/billing",
                            iosIcon: '',
                            meta: { showSideMenu: false },
                            mdIcon: '',
                        },
                        {
                            title: "Open Banking",
                            name: "Open Banking",
                            url: "/account/manage_open_banking",
                            iosIcon: '',
                            meta: { showSideMenu: true },
                            mdIcon: '',
                        },
                    ]
                },
                /*{
                  title: "Profile",
                  name: "Notifications",
                  url: "/home/profile",
                  iosIcon: 'UserCircleIcon',
                  meta: { showSideMenu: true },
                  mdIcon: 'UserCircleIcon',
                  hide: hideBool,
                },*/
                /*{
                  title: "Settings",
                  name: "Settings",
                  meta: { showSideMenu: true },
                  iosIcon: CogIcon,
                  mdIcon: CogIcon,
                  nestedPages: [
                    {
                      title: "Company Profile",
                      name: "Company Profile",
                      url: "/analytics/dashboard",
                      iosIcon: '',
                      meta: { showSideMenu: true },
                      mdIcon: '',
                    },
                    {
                      title: "User Profile",
                      name: "User Profile",
                      url: "/analytics/demo_dashboard_2",
                      iosIcon: '',
                      meta: { showSideMenu: true },
                      mdIcon: '',
                    },
                  ]
                },*/
            ]
        }
    }
});
